































import { defineComponent } from "@vue/composition-api";
import { useAuthStore } from "../store/auth";

export default defineComponent({
  name: "AuthCallback",
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  mounted(): void {
    const authCode: string | null = this.$route.query.code as string | null;
    const verifier: string | null = this.authStore.codeVerifier as
      | string
      | null;

    if (!authCode || !verifier) {
      return;
    }
    this.authStore
      .fetchToken(this.authStore, { authCode, verifier })
      .then(() => {
        this.$router.push("/notifications");
      });
  },
});
